/** @jsx jsx */
import { cn, buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import BlockText from "../block-text";
import localize from "../localize";
import { root, thumbnail, inside, link } from "./preview.module.css";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { Fragment } from "react";
import { jsx } from "theme-ui";


function Preview(props) {
  // console.log(props)
  let prefix = ""
  if (props._type == "service") {
    if (props.locale == "en") prefix = "services"
    if (props.locale == "es") prefix = "servicios"
  }
  if (props._type == "portfolio") {
    if (props.locale == "en") prefix = "portfolio"
    if (props.locale == "es") prefix = "portfolio"
  }
  if (prefix) prefix = prefix + "/"
  var path = ""

  //TODO: bring language support back
  // if (props.slug) path = "/" + props.locale + "/" + prefix + props.slug.current
  if (props.slug) path = "/" + prefix + props.slug.current

  const imageWidth = props.cols === 1 ? 896 : 640

  // console.log("PREVIEW", props)
  return (
    <div className={root}>
      {props.slug ? (
        <Link to={path} sx={{textDecoration: "none"}} className={link}>
          <div className={thumbnail} sx={{variant: "containers.preview.leadMediaThumb"}}>
            {props.mainImage?.asset && (
              <div>
                <img
                  // sx={{
                  //   width: imageWidth,
                  //   maxWidth: "100%"
                  // }}
                  width={imageWidth}
                  height={Math.floor((9 / 16) * imageWidth)}
                  loading="lazy"
                  src={imageUrlFor(buildImageObj(props.mainImage))
                    .width(imageWidth)
                    .height(Math.floor((9 / 16) * imageWidth))
                    // .fit("crop")
                    .fit("max")
                    .auto("format")
                    // .maxWidth(448)
                    // .maxHeight(1000)
                    .quality(100)
                    .url()}
                  // TODO: why reference grid item image doesn't have editable alt?
                  alt={props.mainImage.alt ? props.mainImage.alt : props.title}
                  title={props.title}

                  // srcSet={props.mainImage.asset.fixed.srcSet}
                  // srcSetWebp={props.mainImage.asset.fixed.srcSet}
                  // srcWebp={props.mainImage.asset.fixed.srcWebp}
                />

                {/* <Img
                  fluid={props.mainImage?.asset.fluid}
                  // fixed={props.mainImage?.asset.fixed}
                  alt={props.mainImage?.alt}
                /> */}
              </div>
            )}
          </div>

          {props.type == "inside"
            ? props.title && (
                <div className={inside}>
                  <h3 sx={{variant: "containers.card.inside.h3"}}>{props.title}</h3>
                  <p sx={{variant: "containers.card.inside.p"}}>{props.subtitle}</p>
                </div>
              )
            : props.title && (
                <div className={inside}>
                  <h3>{props.title}</h3>
                  <p>{props.subtitle}</p>
                </div>
              )}
          {props._rawExcerpt && (
            <div className="here2">
              <BlockText blocks={props._rawExcerpt} />
            </div>
          )}
        </Link>
      ) : (
        <div className="here4" sx={{position: "relative"}}>
          <div className="here3" sx={{position: "relative"}} sx={{filter: "brightness(65%)"}}>
            {props.mainImage?.asset && (
              <img
                sx={{
                  width: imageWidth,
                  maxWidth: "100%"
                }}
                loading="lazy"
                src={imageUrlFor(buildImageObj(props.mainImage))
                  .width(imageWidth)
                  .height(Math.floor((9 / 16) * imageWidth))
                  .auto("format")
                  .fit("fillmax")
                  .url()}
                alt={props.mainImage.alt}
              />
              // <Img
              //   fluid={props.mainImage?.asset.fluid}
              //   // fixed={props.mainImage?.asset.fixed}
              //   alt={props.mainImage?.alt}
              // />
            )}
          </div>
          {props.type == "inside"
            ? props.title && (
                <div className={inside}>
                  <h3 sx={{variant: "containers.card.inside.h3"}}>{props.title}</h3>
                  <p sx={{variant: "containers.card.inside.p"}}>{props.subtitle}</p>
                </div>
              )
            : props.title && (
                <Fragment>
                  <h2 sx={{variant: "containers.card.h3"}}>{props.title}</h2>
                  <p sx={{variant: "containers.card.p"}}>{props.subtitle}</p>
                </Fragment>
              )}

          {props._rawExcerpt && (
            <div>
              <BlockText blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

// export default Preview
export default localize(Preview, {propsTotranslate: ["title", "slug"]})