/** @jsx jsx */
import Preview from "./preview"
import {root, list} from "./preview-grid.module.css"
import {jsx} from "theme-ui"

function PreviewGrid(props) {
  const bgColor = props.bg?.bgToggle == "on" ? props.bg?.bgColor : "transparent"

  const {locale} = props
  let gridCols = "1fr"
  if (props.nodes.length == 1 || props.cols == "1") {
    gridCols = "1fr"
  }
  if (props.nodes.length == 2 || props.cols == "2") {
    gridCols = "1fr 1fr"
  }
  if (props.nodes.length == 3 || props.cols == "3") {
    gridCols = "1fr 1fr 1fr"
  }

  return (
    <div className={root} sx={{backgroundColor: bgColor}}>
      {props.title && <h2>{props.title}</h2>}
      <ul
        className={list}
        sx={{
          gridTemplateColumns: ["1fr", gridCols, gridCols]
        }}
      >
        {props.nodes &&
          props.nodes.map((node, key) => {
            return (
              <li key={key}>
                <Preview type={props.type} cols={props.nodes.length} locale={locale} {...node} />
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default PreviewGrid
// export default localize(PreviewGrid, { propsTotranslate: ['title', 'slug', 'title'] })
