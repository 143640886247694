/** @jsx jsx */
import {buildImageObj} from "../../lib/helpers"
import {imageUrlFor} from "../../lib/image-url"
import {jsx} from "theme-ui"


// import styles from './figure.module.css'

function Figure(props) {
  console.log(props)
  return (
    <figure sx={{margin: "auto"}}>
      {props.asset && (
        <img
          sx={{maxWidth: "100%"}}
          src={imageUrlFor(buildImageObj(props)).width(1200).url()}
          alt={props.alt}
          title={props.title}
        />
      )}
      <figcaption>{props.caption}</figcaption>
    </figure>
  )
}

export default Figure